<template>
  <div :style="getElementStyle">
    <div v-if="!isDefalutPosq" style="display: flex">
      <div>
        {{ data.label }}
        <label for="text" style="margin-right: 5px">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold; margin-left: 5px"
        >
          *
        </span>
        <i
          class="el-icon-copy-document"
          v-if="!isDataTableField"
          @click="copyDocument"
        ></i>
        <span v-if="data.description" style="margin-right: 5px">
          <i class="el-icon-info" :title="data.description"></i>
        </span>
      </div>
      <el-col>
        <el-select
          v-model="selectedValue"
          :placeholder="data.placeholder"
          :suffix-icon="data.properties.el_icon"
          :style="getStyle"
        >
          <el-option
            v-for="(option, index) of options"
            :value="option.value"
            :key="index"
            :label="option.name"
            :suffix-icon="data.properties.el_icon"
          ></el-option>
        </el-select>
        <span v-if="isActive" class="setting-icon" @click="openSettings">
          <i class="el-icon-s-tools" />
        </span>
      </el-col>
    </div>
 
    <el-row align="middle" v-else>
      <el-col
        :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24"
        :style="computedStyles"
      >
        <label for="text" style="margin-right: 5px">{{ data.label }}</label>
        <span
          v-if="data.validations.required"
          style="color: red; font-weight: bold"
        >
          *
        </span>
        <i
          class="el-icon-copy-document"
          v-if="!isDataTableField"
          @click="copyDocument"
        ></i>
        <span>
          <el-popover
            v-if="data.description"
            placement="top-start"
            trigger="hover"
            :content="data.description"
          >
            <i class="el-icon-info" slot="reference"></i>
          </el-popover>
        </span>
      </el-col>
      <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
        <div>
          <el-select
            v-model="selectedValue"
            :placeholder="data.placeholder"
            :suffix-icon="data.properties.el_icon"
            :style="getStyle"
          >
            <el-option
              v-for="(item, index) of options"
              :value="item.value"
              :key="index"
              :label="item.label"
              :suffix-icon="data.properties.el_icon"
            ></el-option>
          </el-select>
          <span
            v-if="isActive || isDataTableField"
            class="setting-icon"
            @click="openSettings"
          >
            <i class="el-icon-s-tools" />
          </span>
        </div>
      </el-col>
    </el-row>
  </div>
</template>
<script>
import { postAPICall } from "../../../helpers/httpHelper";
export default {
  components: {},
  props: ["data", "value", "isActive", "isDataTableField", "isInDataTable","fieldsData"],
  computed: {
    selectedValue: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit("input", value);
      },
    },
    computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    getStyle() {
      return `height:${this.data.height - 30}px;`;
    },
    getElementStyle() {
      let borderStyle = "";
      if (this.data.styles) {
        let type = this.data.styles.border_type
          ? this.data.styles.border_type
          : "solid";
        let size = this.data.styles.border_size
          ? this.data.styles.border_size + "px"
          : "0px";
        let color = this.data.styles.border_color
          ? this.data.styles.border_color
          : "";
        let font_size =
          this.data.styles &&
          this.data.styles.font &&
          this.data.styles.font.font_size
            ? this.data.styles.font.font_size
            : 14;
        let bold =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 1 || this.data.styles.font.style == 3)
            ? "bold"
            : "";
        let italic =
          this.data.styles &&
          this.data.styles.font &&
          (this.data.styles.font.style == 2 || this.data.styles.font.style == 3)
            ? "italic"
            : "";
 
        borderStyle = "border:" + type + " " + " " + size + " " + color;
 
        borderStyle += ";";
        borderStyle += this.data.styles.background
          ? `background: ${this.data.styles.background}`
          : "";
        borderStyle += ";";
        borderStyle += "font-size:" + font_size + "px;";
        if (bold) {
          borderStyle += "font-weight:" + bold + ";";
        }
        if (italic) {
          borderStyle += "font-style:" + italic + ";";
        }
        borderStyle += this.data.styles.label_color
          ? `color: ${this.data.styles.label_color}`
          : "";
        borderStyle += ";";
      }
      return borderStyle;
    },
 
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
  },
  data() {
    return {
      validations: [],
      options: [],
      showLabel: true,
      integrations: [],
      integrationVariables: [],
      integrationVariablesObj : {},
    };
  },
  async mounted() {
    this.options = this.data.options || [];
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
    }
    await this.fetchAllPredefinedIntegrations();
    await this.getOptionsData();
  },
  methods: {
    copyDocument() {
      this.$emit("copy-document", this.data.key);
    },
    openSettings() {
      this.$emit("settings");
    },
    async getOptionsData() {
      try {
        if (!Array.isArray(this.fieldsData)) {
          return;
        }
        let variableSelected =
          this.data.integration_settings?.selectedIntegrationFieldVariable;
        let integrationVariableField = this.fieldsData?.find(
          (variableField) =>
            variableField.key ===
            this.data.integration_settings?.selectedIntegrationField
        );
        if (!integrationVariableField) {
          return;
        }
 
        let connectionField = this.fieldsData?.find(
          (field) => field.integration_settings?.selectedIntegration
        );
 
        if (!connectionField) {
          return;
        }
 
        let applicationSelected =
          integrationVariableField.integration_settings?.selectedApplication;
 
        let application = this.predefinedIntegrations?.find(
          (selectedApp) => selectedApp._id === applicationSelected
        );
 
        if (!application) {
          return;
        }
 
        let eventsArray = application?.events || [];
 
        let eventSelected =
          this.data.integration_settings?.selectedVariableEvent;
 
        let event = eventsArray.find(
          (selectedEvent) => String(selectedEvent._id) === eventSelected
        );
 
        if (
          !event ||
          !event.settings ||
          !Array.isArray(event.settings.availableColumns)
        ) {
          return;
        }
 
        let allColumns = event.settings.availableColumns;
 
        let columnFound = allColumns.find(
          (selectedColumn) => String(selectedColumn.key) === variableSelected
        );
        if (columnFound && columnFound.isApiData) {
          let requiredData = {};
          let detailKey = columnFound.requiredAsset;
          let requestBody = {
            selectedApplication: application.key,
            selectedEvent: detailKey,
            selectedIntegration:
              connectionField.integration_settings?.selectedIntegration,
            requiredFields: requiredData,
          };
          let response = await this.fetchRequiredFieldData(requestBody);
          variableSelected =
            this.data.integration_settings?.selectedIntegrationFieldVariable;
          // if (response && Array.isArray(response)) {
          //   this.integrationVariablesObj = {};
          //   this.options = response.map((option) => {
          //     this.integrationVariablesObj[option.id] = option.name;
          //     return {
          //       label: option.name,
          //       value: option.id,
          //     };
          //   });
          // }
          if (response && Array.isArray(response)) {
        this.data.options = response.map((option) => ({
          label: option.name,
          value: option.id,
        }));
      }
        }
      } catch (err) {
        return err;
      }
    },
    async fetchRequiredFieldData(payload) {
      try {
        const provider = payload.selectedApplication;
        const requestBody = {
          provider,
          field: payload.selectedEvent,
          formData: payload.requiredFields,
        };
        const response = await postAPICall(
          "POST",
          `integrations/service/get-requiredFields-data/${payload.selectedIntegration}`,
          requestBody
        );
        const data = response?.data || {};
        return data;
      } catch (error) {
        return error;
      }
    },
    async fetchAllPredefinedIntegrations() {
      const response = await postAPICall(
        "GET",
        "integrations/service/get-all-applications"
      );
      this.predefinedIntegrations = response?.data || [];
    },
  },
};
</script>
 
<style lang="scss" scoped>  
.el-select {
  width: 100% !important;
 
  .el-input,
  .el-input__inner {
    height: inherit !important;
  }
}
</style>
 